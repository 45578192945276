import Vue from "vue";
import Vuex from "vuex";
import LogInStore from "./SignIn/Signin";
import Companies from "./Companies/Companies";
import Teams from "./Teams/Teams";
import users from "./users";
import channels from "./channels";
import Admins from "./Admins/Admins";
import logs from "./Logs";
import payment from "./Payment/payment";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    LogInStore,
    Companies,
    Teams,
    users,
    channels,
    Admins,
    logs,
    payment,
  },
});
