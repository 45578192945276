import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                dialoge_desc_color: "#403F56",
                user_header: "#423D68",
                primary: "#5765FB", //color for modal btns
                secondary: "#423D68", // will be used in outerscreen buttons and left panel and teams area
                error: "#FB374E",
                "left-panel-channel-bg": "#2F2C54",
                "text-message": "#3D3C48", //text message color in chatbox
                link: "#2B67E8", //link color
                "selected-team": "#EEEFF7", //selected team color
                "channel-search-bar": "#423D68",
                "message-area-bg": "#F9F9FC",
                "sender-message-bg": "#EEEFF7",
                //ADMIN SIDE THEME PROPERTIES
                'admin-primary':"#432662",
                'tableHeaderBg':"#EEEEEE",
                "admin-left-panel-bg": "#39032E",
                'admin-left-panel-activeTab':'#7C378C',
                'admin-left-panel-header':'#7C378C',
                'admin-tooltip-color':"#8C3785",
                'modal-overlay':"#422F57"
            },
        },
    },
});