import axios from "axios";
import config from "@/config.js";
const payment = {
  state: () => ({
    paymentDetails: [],
    pagination: null,
    currentInvoice: null,
  }),
  getters: {
    getPaymentDetails: (state) => state.paymentDetails,
    getPagination: (state) => state.pagination,
    getCurrentInvoice: (state) => state.currentInvoice,
  },
  actions: {
    paymentDetails({ commit }, payload) {
      return new Promise((resolve, reject) => {
        let token = localStorage.getItem("token");
        axios
          .post(
            config.API_URL_ROOT + "/payment/detail",
            { page: payload.page, per_page: payload.per_page },
            {
              headers: { token: token },
            }
          )
          .then(
            (response) => {
              commit("SET_PAYMENT_DETAILS", response.data.data);
              commit("SET_PAGINATION", response.data.pagination);
              resolve(response);
            },
            (error) => {
              if (error.response.status == 401) {
                commit("clearStorage");
              }
              reject(error);
            }
          );
      });
    },

    currentInvoice({ commit }, payload) {
      return new Promise((resolve, reject) => {
        let token = localStorage.getItem("token");
        axios
          .post(
            config.API_URL_ROOT + "/payment/getLatestInvoice",
            {
              company_id: payload,
            },
            {
              headers: { token: token },
            }
          )
          .then(
            (response) => {
              commit("SET_CURRENT_INVOICE", response.data.data);
              resolve(response);
            },
            (error) => {
              if (error.response.status == 401) {
                commit("clearStorage");
              }
              reject(error);
            }
          );
      });
    },

    unlimitedFreeTrial({ commit }, payload) {
      return new Promise((resolve, reject) => {
        let token = localStorage.getItem("token");
        axios
          .post(config.API_URL_ROOT + "/payment/setUnlimitedAccess", payload, {
            headers: { token: token },
          })
          .then(
            (response) => {
              resolve(response);
            },
            (error) => {
              if (error.response.status == 401) {
                commit("clearStorage");
              }
              reject(error);
            }
          );
      });
    },

    collectivePaymentAction({ commit }, payload) {
      return new Promise((resolve, reject) => {
        let token = localStorage.getItem("token");
        axios
          .post(config.API_URL_ROOT + "/payment/collectivePayment", payload, {
            headers: { token: token },
          })
          .then(
            (response) => {
              resolve(response);
            },
            (error) => {
              if (error.response.status == 401) {
                commit("clearStorage");
              }
              reject(error);
            }
          );
      });
    },
  },
  mutations: {
    SET_PAYMENT_DETAILS(state, payload) {
      state.paymentDetails = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_CURRENT_INVOICE(state, payload) {
      state.currentInvoice = payload;
    },
    SET_UNLIMITED_FREE_TRIAL(state, payload) {
      state.paymentDetails.find(
        (company) => company._id == payload.company_id
      ).do_not_block = payload.do_not_block;
    },
  },
};
export default payment;
