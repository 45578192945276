//System: Whistle it
//module: Super Admin
//Developer: Gulraiz Ahmed
//Date: 17-06-2021
//Organization: Programmers Force
//Purpose: Main router in which i can set the path of views

import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "SignIn",
    component: () => import("../views/signIn.vue"),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("token")) {
        next("/dashboard");
      } else {
        next();
      }
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../views/dashboard.vue"),
    meta: { requireAuth: true },

    children: [
      {
        path: "/users",
        name: "users",
        component: () => import("../views/users.vue"),
      },
      {
        path: "/dashboard",
        name: "dashboardstats",
        component: () => import("../views/dashboardstats.vue"),
      },
      {
        path: "/teams",
        name: "teams",
        component: () => import("../views/teams.vue"),
      },
      {
        path: "/companies",
        name: "companies",
        component: () => import("../views/companies.vue"),
      },
      {
        path: "/channels",
        name: "channels",
        component: () => import("../views/channels.vue"),
      },
      {
        path: "/admins",
        name: "admins",
        component: () => import("../views/admins.vue"),
      },
      {
        path: "/setting",
        name: "setting",
        component: () => import("../views/setting.vue"),
      },
      {
        path: "/logs",
        name: "logs",
        component: () => import("../views/logs.vue"),
      },
      {
        path: "/payments",
        name: "payments",
        component: () => import("@/components/Payment/payments.vue"),
      },
    ],
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requireAuth)) {
    if (!localStorage.getItem("token")) {
      next("/");
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router;
