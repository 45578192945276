import axios from 'axios'
import config from "@/config.js";
const Teams = {
    state: () => ({
        Teams: [],
        totalRow: 0
    }),

    mutations: {
        Teams(state, responseData) {
            state.Teams = responseData;
        },
        TotalRows(state, Rows) {

            state.totalRow = Rows;
        }
    },

    actions: {
        Teams({ commit }) {
            return new Promise((resolve, reject) => {
                let token = localStorage.getItem("token");
                // Do something here... lets say, a http call using vue-resource
                axios
                    .get(config.API_URL_ROOT + "/team/getTeams", {
                        headers: { token: token },
                    })
                    .then(
                        (response) => {
                            commit("Teams", response.data);
                            commit("TotalRows", response.data.length)

                            // http success, call the mutator and change something in state
                            resolve(response); // Let the calling function know that http is done. You may send some data back
                        })
                    .catch((error) => {

                        // http failed, let the calling function know that action did not work out
                        reject(error);
                    })
            });
        },
        CompanyTeams({ commit }, id) {
            return new Promise((resolve, reject) => {
                let token = localStorage.getItem("token");
                const options = {
                    headers: { token: token }
                };
                // Do something here... lets say, a http call using vue-resource
                axios
                    .post(config.API_URL_ROOT + "/company/getCompanyTeams", {
                        company_id: id

                    }, options)
                    .then(
                        (response) => {
                            commit("Teams", response.data);
                            commit("TotalRows", response.data.length)
                                // http success, call the mutator and change something in state
                            resolve(response); // Let the calling function know that http is done. You may send some data back
                        }, (error) => {
                            // http failed, let the calling function know that action did not work out
                            reject(error);
                        }
                    );
            });
        },




    },

    getters: {
        getTeams(state) {
            return state.Teams;
        },

        getTotalRow(state) {
            return state.totalRow;
        }

    }
}
export default Teams;