// System: Whistle it 
// Module: Super Admin
// Developer: M Saud Saleem
// Date: 16-12-2021 
// Organization: Whistle it 
// Purpose: The purpose of this File is to  deal with logs

import axios from "axios";
import config from "@/config.js";
const users = {

    //<------------------------------------Actions------------------------------------>   
    actions: {
        // Fetch Impersonate Logs Data
        fetchImpersonateLogs ( context, payload )
        {
            return new Promise( ( resolve, reject ) =>
            {
                let token = localStorage.getItem( "token" );
                axios
                    .post(
                        config.API_URL_ROOT + "/getSuperAdminLogs",
                        { page: payload.page, per_page: payload.per_page, search: payload.search },
                        {
                            headers: { token: token },
                        }
                    )
                    .then(
                        ( response ) =>
                        {
                            resolve( response );
                        },
                        ( error ) =>
                        {
                            reject( error );
                        }
                    );
            } );
        },

        // Fetch User Activity Logs Data
        fetchUserActivityLogs ( context, payload )
        {
            return new Promise( ( resolve, reject ) =>
            {
                let token = localStorage.getItem( "token" );
                axios
                    .post(
                        config.API_URL_ROOT + "/getUserAcitivityLogs",
                        { page: payload.page, per_page: payload.per_page, search: payload.search },
                        {
                            headers: { token: token },
                        }
                    )
                    .then(
                        ( response ) =>
                        {
                            resolve( response );
                        },
                        ( error ) =>
                        {
                            reject( error );
                        }
                    );
            } );
        },

        // Fetch Fcm Logs Data
        fetchFcmLogs ( context, payload )
        {
            return new Promise( ( resolve, reject ) =>
            {
                let token = localStorage.getItem( "token" );
                axios
                    .post(
                        config.API_URL_ROOT + "/getFcmLogs",
                        { page: payload.page, per_page: payload.per_page, search: payload.search },
                        {
                            headers: { token: token },
                        }
                    )
                    .then(
                        ( response ) =>
                        {
                            resolve( response );
                        },
                        ( error ) =>
                        {
                            reject( error );
                        }
                    );
            } );
        },

        // Fetch User Activity Details Logs Data
        fetchDetailLogs ( context, requestObj )
        {
            return new Promise( ( resolve, reject ) =>
            {
                let token = localStorage.getItem( "token" );
                axios
                    .post( config.API_URL_ROOT + "/getDetailLogs",
                        { action_log_id: requestObj.userLogId },
                        {
                            headers: { token: token },
                        }
                    )
                    .then(
                        ( response ) =>
                        {
                            resolve( response );
                        },
                        ( error ) =>
                        {
                            reject( error );
                        }
                    );
            } );
        },

        // Fetch Fcm Details Logs Data
        fetchFcmDetailLogs ( context, requestObj )
        {
            return new Promise( ( resolve, reject ) =>
            {
                let token = localStorage.getItem( "token" );
                axios
                    .post( config.API_URL_ROOT + "/getFcmDetailLogs",
                        { fcm_log_id: requestObj.fcmLogId },
                        {
                            headers: { token: token },
                        }
                    )
                    .then(
                        ( response ) =>
                        {
                            resolve( response );
                        },
                        ( error ) =>
                        {
                            reject( error );
                        }
                    );
            } );
        },
    },
}
export default users;