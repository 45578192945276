// System: Whistle it
// Module: Super Admin
// Developer: Gulraiz Ahmed
// Date: 06-07-2021
// Organization: Whistle it
// Purpose: The purpose of this File is to  deal Users actions

import axios from "axios";
import config from "@/config.js";
const users = {
  //<------------------------------------State------------------------------------->
  state: () => ({
    UsersData: [],
    CompaniesData: [],
    totalPage: 0,
  }),
  //<------------------------------------Getters----------------------------------->
  getters: {
    getUsersdata: (state) => state.UsersData,
    getCompaniesdata: (state) => state.CompaniesData,
    getTotalPages: (state) => state.totalPage,
  },
  //<------------------------------------Actions------------------------------------>
  actions: {
    getUsers({ commit }) {
      return new Promise((resolve, reject) => {
        let token = localStorage.getItem("token");
        axios
          .get(config.API_URL_ROOT + "/user/getUsers", {
            headers: { token: token },
          })
          .then(
            (response) => {
              commit("getUsers", response.data);
              commit("getTotalPages", response.data.last_page);
              resolve(response);
            },
            //<------------------------------Error 401 handling--------------------------------->
            (error) => {
              if (error.response.status == 401) {
                commit("clearStorage");
              }
              reject(error);
            }
          );
      });
    },
    CompanyUsers({ commit }, id) {
      return new Promise((resolve, reject) => {
        let token = localStorage.getItem("token");
        const options = {
          headers: { token: token },
        };
        // Do something here... lets say, a http call using vue-resource
        axios
          .post(
            config.API_URL_ROOT + "/company/getCompanyUsers",
            {
              company_id: id,
            },
            options
          )
          .then(
            (response) => {
              commit("getUsers", response.data);
              commit("getTotalPages", response.data.length);
              // http success, call the mutator and change something in state
              resolve(response); // Let the calling function know that http is done. You may send some data back
            },
            (error) => {
              // http failed, let the calling function know that action did not work out
              reject(error);
            }
          );
      });
    },
    openChat(context, id) {
      return new Promise((resolve, reject) => {
        let token = localStorage.getItem("token");
        const options = {
          headers: { token: token },
        };
        axios
          .post(
            config.API_URL_ROOT + "/superAdminGetUserData",
            { user_id: id },
            options
          )
          .then(
            (response) => {
              if (response.status == 200) {
                resolve(response);
                window.open(
                  "https://app.whistleit.io/loginloader?user_token=" +
                    response.data.token +
                    "&user_id=" +
                    response.data.user_id
                );
              } else {
                reject(response);
              }
            },
            (error) => {
              reject(error);
            }
          );
      });
    },
  },
  //<------------------------------------Mutations------------------------------------->
  mutations: {
    getUsers(state, payload) {
      state.UsersData = payload;
    },
    getTotalPages(state, payload) {
      state.totalPage = payload;
    },
  },
};
export default users;
