import axios from "axios";
import config from "@/config.js";
const Companies = {
  state: () => ({
    admins: [],
  }),

  mutations: {
    Admins(state, payload) {
      state.admins = payload;
    },
    removeAdmins(state, payload) {
      state.admins = state.admins.filter((admin) => admin._id != payload.id);
    },
    addAdmin(state, payload) {
      state.admins.push(payload);
    },
  },

  actions: {
    addNewAdmin(context, admin) {
      return new Promise((resolve, reject) => {
        let token = localStorage.getItem("token");
        const options = {
          headers: { token: token },
        };
        axios
          .post(config.API_URL_ROOT + "/superAdmin/add", admin, options)
          .then(
            (response) => {
              if (response.status == 200) {
                context.commit("addAdmin", response.data);

                resolve(response);
              } else {
                reject(response);
              }
            },
            (error) => {
              reject(error);
            }
          );
      });
    },
    Admins(context) {
      return new Promise((resolve, reject) => {
        let token = localStorage.getItem("token");
        axios
          .post(
            config.API_URL_ROOT + "/superAdmin/read",
            {},
            {
              headers: { token: token },
            }
          )
          .then(
            (response) => {
              console.log(response);
              context.commit("Admins", response.data);

              resolve(response);
            },
            //<------------------------------Error handling--------------------------------->
            (error) => {
              if (error.response.status == 401) {
                commit("clearStorage");
              }
              reject(error);
            }
          );
      });
    },
    removeAdmin(context, id) {
      return new Promise((resolve, reject) => {
        let token = localStorage.getItem("token");
        const options = {
          headers: { token: token },
        };
        axios
          .post(
            config.API_URL_ROOT + "/superAdmin/remove",
            { super_admin_id: id, deleted_at: true },
            options
          )
          .then(
            (response) => {
              console.log(response);
              if (response.status == 200) {
                context.commit("removeAdmins", { id });
                resolve(response);
              } else {
                reject(response);
              }
            },
            (error) => {
              reject(error);
            }
          );
      });
    },
    changePassword(context, obj) {
      return new Promise((resolve, reject) => {
        let token = localStorage.getItem("token");
        const options = {
          headers: { token: token },
        };
        axios
          .post(
            config.API_URL_ROOT + "/superAdmin/updatePassword",
            obj,
            options
          )
          .then(
            (response) => {
              console.log(response);
              if (response.status == 200) {
                resolve(response);
              } else {
                reject(response);
              }
            },
            (error) => {
              reject(error);
            }
          );
      });
    },
  },

  getters: {
    getAdmins(state) {
      return state.admins;
    },
  },
};
export default Companies;
