// System: Whistle it 
// Module: Super Admin
// Developer: Gulraiz Ahmed 
// Date: 06-07-2021 
// Organization: Whistle it
// Purpose: The purpose of this File is to  deal Channels actions

import axios from "axios";
import config from "@/config.js";
const channels = {
    //<------------------------------------State-------------------------------------->   
    state: {
        channelsData: [],
        totalPage: 0,
    },
    //<------------------------------------Getters------------------------------------>   
    getters: {
        getChannelsData: (state) => state.channelsData,
        getPagesNo: (state) => state.totalPage,
    },
    //<------------------------------------Actions------------------------------------>   
    actions: {
        /* This action is responsible for getting all channels
        Make an api call to get all channels and then commit the mutation to update state
        args: commit, page(actual page no getting from component)
        */
        getChannels({ commit }) {
            return new Promise((resolve, reject) => {
                let token = localStorage.getItem("token");
                axios
                    .get(config.API_URL_ROOT + "/channel/getChannels", {
                        headers: { token: token }
                    })
                    .then(
                        (response) => {
                            commit("getChannels", response.data);
                            commit("getPagesNo", response.data.last_page)
                            resolve(response);
                        },
                        //<------------------------------Error 401 handling--------------------------------->   
                        (error) => {
                            if (error.response.status == 401) {
                                commit('clearStorage')
                            }
                            reject(error);
                        }
                    );
            });
        },
        CompanyChannels({ commit }, id) {
            return new Promise((resolve, reject) => {
                let token = localStorage.getItem("token");
                const options = {
                    headers: { token: token }
                };
                // Do something here... lets say, a http call using vue-resource
                axios
                    .post(config.API_URL_ROOT + "/company/getCompanyChannels", {
                        company_id: id
                    }, options)
                    .then(
                        (response) => {
                            commit("getChannels", response.data);
                            commit("getPagesNo", response.data.last_page)
                                // http success, call the mutator and change something in state
                            resolve(response); // Let the calling function know that http is done. You may send some data back
                        }, (error) => {
                            // http failed, let the calling function know that action did not work out
                            reject(error);
                        }
                    );
            });
        },
    },
    //<------------------------------------Mutations------------------------------------->   
    mutations: {
        getChannels(state, payload) {
            state.channelsData = payload;
        },
        getPagesNo(state, payload) {
            state.totalPage = payload;
        }
    }
}
export default channels;