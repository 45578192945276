import axios from 'axios'
import config from "@/config.js";
const Companies = {
    state: () => ({
        Companies: [],
        CompaniesName: [],
        List: [],
        totalRow: 0,
        totalPages: 0
    }),

    mutations: {
        Companies(state, responseData) {
            state.Companies = responseData;
        },
        TotalRows(state, Rows) {

            state.totalRow = Rows;
        },
        CompaniesList(state, payload) {
            let list = payload.map((item) => {
                return {
                    id: item._id,
                    name: item.name
                }
            })
            state.CompaniesName = list;
        },
        TotalPages(state, pageCount) {
            state.totalPages = pageCount;
        }
    },


    actions: {
        Companies({ commit }) {
            return new Promise((resolve, reject) => {
                let token = localStorage.getItem("token");
                // Do something here... lets say, a http call using vue-resource
                axios
                    .get(config.API_URL_ROOT + "/company/getCompanies", {
                        headers: { token: token },
                    })
                    .then(
                        (response) => {
                            commit("Companies", response.data);
                            commit("CompaniesList", response.data);
                            commit("TotalRows", response.data.length)
                            commit("TotalPages", response.data.last_page);
                            // http success, call the mutator and change something in state
                            resolve(response); // Let the calling function know that http is done. You may send some data back
                        }, (error) => {
                            // http failed, let the calling function know that action did not work out
                            reject(error);
                        }
                    );
            });
        },

    },

    getters: {
        getCompanies(state) {
            return state.Companies;
        },
        getTotalRows(state) {
            return state.totalRow;
        },
        getTotalPages1(state) {
            return state.totalPages;
        },
        getCompaniesNames(state) {
            return state.CompaniesName;
        },

    }
}
export default Companies;