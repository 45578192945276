// System: Whistle it 
// Module: Super Admin
// Developer: Gulraiz Ahmed 
// Date: 06-07-2021 
// Organization: Whistle it 
// Purpose: The purpose of this File is to  deal SignIn and Sign_Out actions

import axios from "axios";
import router from "@/router";
import config from "@/config.js";
//<------------------------------------State------------------------------------->
const state = {
    signInCredentials: [],
};
//<------------------------------------Getters----------------------------------->
const getters = {
    getSignInCredentials: (state) => state.signInCredentials,
};
//<------------------------------------Actions------------------------------------>
const actions = {
    SignInCredentials({ commit }, newSignIn) {
        return new Promise((resolve, reject) => {
            // Do something here... lets say, a http call using vue-resource
            axios.post(config.API_URL_ROOT + "/login", newSignIn).then(
                (response) => {
                    localStorage.setItem("token", response.data.token);
                    localStorage.setItem("data", JSON.stringify(response.data));
                    commit("SignInCredentials", response.data);
                    // http success, call the mutator and change something in state
                    resolve(response); // Let the calling function know that http is done. You may send some data back
                },
                (error) => {
                    // http failed, let the calling function know that action did not work out
                    reject(error);
                }
            );
        });
    },
    //<------------------------------------SignOut------------------------------------>
    SignOutClear({ commit }) {
        let token = localStorage.getItem("token");
        let options = {
            headers: { token: token },
        }
        return new Promise((resolve, reject) => {
            axios
                .post(config.API_URL_ROOT + "/logout", {}, options)
                .then(function(response) {
                    commit("clearStorage");
                    router.push("/");
                    resolve();
                })
                .catch(function(error) {
                    if (error.response.status == 401) {
                        commit("clearStorage");
                    }
                    reject(error)
                });
        });
    },
};
//<------------------------------------Mutations-------------------------------------------->
const mutations = {
    SignInCredentials(state, payload) {
        state.signInCredentials.FullName = payload.FullName;
        state.signInCredentials.CompanyName = payload.CompanyName;
    },
    //<------------------------------------Clear-localstorage------------------------------------>
    clearStorage() {
        localStorage.clear();
    },
};
export default { state, getters, mutations, actions };